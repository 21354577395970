.input-error {
    border-color: #dc3545;
    background-color: #fff6f6;
  }
  
  .input-error::placeholder {
    color: #dc3545;
  }
  
  .input-error:focus {
    border-color: #dc3545;
    box-shadow: 0 0 5px rgba(220, 53, 69, 0.5);
  }
  


  .validation-message-endDate {
    // display: none;
    position: absolute;
    background-color: #000;
    color: #eee;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 5px 10px;
    z-index: 1000;
    white-space: nowrap;
    box-shadow: 4px 4px 4px rgba(100, 100, 100, 0.5);
    margin-left:1rem;
    position: relative;
    left:51%;
    top:0%;
    width: max-content;
  }
  .validation-message-endDate::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #000 transparent transparent;
  }

  .validation-message {
    // display: none;
    // position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
   
    padding: 5px 10px;
    z-index: 1000;
    white-space: nowrap;
    margin-left:1rem;
    width: max-content;
  }
  
  // .validation-message::before {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   left: -10px;
  //   margin-top: -5px;
  //   border-width: 5px;
  //   border-style: solid;
  //   border-color: transparent #000 transparent transparent;
  // }


  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
 .tooltip-icon {
    background-color: #f2a30d;
    color: white;
    font-weight: bold;
    padding: 5px;
    border-radius: 50%;
    text-align: center;
    width: 20px;
    height: 20px;
    display: inline-block;
  }
  
 .tooltip-text {
  
   
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
   
    z-index: 1;
    
  
   
  }
  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px; /* Position arrow on the left */
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
  }
  